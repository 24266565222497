import React from 'react';
import {NSCartResume, NSContext} from "aqlrc";
import { Link } from 'routes';
import {withI18next} from "../lib/withI18n";

/**
 * NSCartResume - Affiche le bloc résumé du panier
 * @prop t: (function) fonction translation de i18n
 * @prop gNext: (object) variables Next : Link
 * @return {React.Component}
 *
 * Nécessite pour fonctionner des données de contexte suivantes :
 * - "cart" présent dans le contexte de la state de la page
 * - "lang", "routerLang" / "urlLang" présents dans le contexte de la state de la page (chargés dans _app.js)
 *
 */

export class CartResumeCustom extends NSCartResume {

  render() {
    const { t, gNext } = this.props;
    const Link = (gNext && gNext.Link) || undefined;
    const { showAddresses, deliveryPrice, deliveryName } = this.state;
    const { props, state } = this.context;
    if (!props || !state) { return null; }
    const { lang, routerLang, urlLang, user } = props;
    const { cart } = state;
    let totalDiscount = 0;
    if (cart.promos && cart.promos.length) {
      for (let i = 0; i < cart.promos.length; i++) {
        totalDiscount += cart.promos[i].discountATI;
      }
    }

    const offreASSO = user.attributes.find((attr) => attr.code === 'offre-cse').value;
    const useDeliveryPrice = cart.delivery !== undefined && cart.delivery.method;
    return (
      <aside className="sidebar sidebar--alt hidden-xs">
        <div className="widget-order-sum">
          <h4 className="widget-title">{t('cart:page.resume.recap')}</h4>{ /* <!--/.widget-title --> */ }
          <div className="products-sum" hidden={!(cart.items && cart.items.length > 0)}>
            {
              cart.items && cart.items.map((item) => {
                let imgDefault = `/images/${item.selected_variant ? 'productsVariant' : 'products'}/260x200/${item.image}/${item.slug}.jpg`;
                let imgAlt = 'illustration produit';
                return (
                  <div key={item._id} hidden={item.typeDisplay} className="product-small" style={{ height: '65px' }}>
                    <div className="product__image">
                      <img style={{ objectFit: 'contain' }} src={imgDefault} alt={imgAlt} />
                    </div>{ /* <!--/.product__image --> */ }

                    <div className="product__content">
                      <h6>{item.name || item.id.name} <span style={{ color: 'black', fontSize: '14px' }}>x{item.quantity}</span></h6>

                      <p>{item.id && item.id.description1 ? item.id.description1.title : null}</p>
                    </div>{ /* <!--/.product__content --> */ }
                  </div>
                );
              })
            }
          </div>{ /* <!--/.products-sum --> */ }

          <div className="payment-sum">
            <div className="price-list">
              <div className="price">
                <div className="price__label">
                  <p>{t('cart:page.resume.sousTotal')}</p>
                </div>{ /* <!--/.price__label --> */ }

                <div className="price__value">
                  <strong>{cart.priceSubTotal.ati.toFixed(2)} €</strong>
                </div>{ /* <!--/.price__value --> */ }
              </div>{ /* <!--/.price --> */ }

              <div hidden={totalDiscount === 0} className="price">
                <div className="price__label">
                  <p>
                    {t('cart:page.resume.discount')}
                  </p>
                </div>{ /* <!--/.price__label --> */ }

                <div className="price__value">
                  <strong>- {totalDiscount.toFixed(2)} €</strong>
                </div>{ /* <!--/.price__value --> */ }
              </div>

              <div className="price">
                <div className="price__label">
                  <p>
                    {t('cart:page.resume.expedition')}
                  </p>
                </div>{ /* <!--/.price__label --> */ }

                <div className="price__value">
                  <strong>{deliveryPrice.ati.toFixed(2)} €</strong>
                </div>{ /* <!--/.price__value --> */ }
              </div>
              <div><p><em>{deliveryName}</em></p></div>
              { /* <!--/.price --> */ }
              {
                cart.additionnalFees.ati > 0 && (
                  <div className="price">
                    <div className="price__label">
                      <p>
                        {t('cart:page.cart.additionnal_fees')}
                      </p>
                    </div>{ /* <!--/.price__label --> */ }

                    <div className="price__value">
                      <strong>{cart.additionnalFees.ati.toFixed(2)} €</strong>
                    </div>{ /* <!--/.price__value --> */ }
                  </div>
                  /* <!--/.price --> */
                )
              }
              {
                !offreASSO && (
                  <div className="price">
                    <div className="price__label">
                      <p>
                        <strong>{t('cart:page.resume.totalHT')}</strong>
                      </p>
                    </div>
                    { /* <!--/.price__label --> */}

                    <div className="price__value">
                      {
                        useDeliveryPrice
                          ?
                          <strong>{cart.priceTotal && cart.delivery && cart.delivery.value ? cart.priceTotal.et.toFixed(2) : 0} €</strong>
                          :
                          <strong>{cart.priceTotal && deliveryPrice ? (cart.priceTotal.et + deliveryPrice.et).toFixed(2) : 0} €</strong>
                      }
                    </div>
                  </div>
                )
              }
              { !offreASSO && (
              <div className="price">
                <div className="price__label">
                  <p>
                    <strong>{t('cart:page.resume.taxes')} </strong>
                  </p>
                </div>{ /* <!--/.price__label --> */ }

                <div className="price__value">
                  {
                    useDeliveryPrice
                      ? <strong>{cart.priceTotal && cart.delivery && cart.delivery.value ? (cart.priceTotal.ati - cart.priceTotal.et).toFixed(2) : 0} €</strong>
                      : <strong>{cart.priceTotal && deliveryPrice ? ((cart.priceTotal.ati + deliveryPrice.ati) - (cart.priceTotal.et + deliveryPrice.et)).toFixed(2) : 0} €</strong>

                  }
                </div>
              </div>
              )
              }
              <div className="price price--total">
                <div className="price__label">
                  <p><strong>{t('cart:page.resume.totalTTC')}</strong></p>
                </div>{ /* <!--/.price__label --> */ }

                <div className="price__value">
                  {
                    useDeliveryPrice
                      ? <strong>{cart.priceTotal ? cart.priceTotal.ati.toFixed(2) : 0} €</strong>
                      : <strong>{cart.priceTotal && deliveryPrice ? (cart.priceTotal.ati + deliveryPrice.ati).toFixed(2) : 0} €</strong>
                  }
                </div>{ /* <!--/.price__value --> */ }
              </div>{ /* <!--/.price --> */ }
            </div>{ /* <!--/.price-list --> */ }
          </div>{ /* <!--/.payment-sum --> */ }
          {   cart.addresses && cart.addresses.billing && cart.addresses.delivery && showAddresses
          && (
            <div className="address-sum">
              <div className="address">
                <h6>{t('cart:page.resume.adresseFacturation')}</h6>

                <p>
                  {cart.addresses.billing.line1} <br />
                  {cart.addresses.billing.line2}
                  <br />
                  {cart.addresses.billing.zipcode} • {cart.addresses.billing.isoCountryCode}
                </p>
                {
                  Link !== undefined
                    ? (
                      <Link route="cartAddress" params={{ lang: routerLang }}>
                        <a className="link-edit">{t('cart:page.resume.modifier')}</a>
                      </Link>
                    ) : (<a href={`${urlLang}/cart/address`} className="link-edit">{t('cart:page.resume.modifier')}</a>)
                }
              </div>{/* <!-- /.address --> */}

              {/*<div className="address">*/}
              {/*  <h6>{t('cart:page.resume.adresseLivraison')}</h6>*/}

              {/*  <p>*/}
              {/*    {cart.addresses.delivery.line1} <br />*/}
              {/*    {cart.addresses.delivery.line2}*/}
              {/*    <br />*/}
              {/*    {cart.addresses.delivery.zipcode} • {cart.addresses.delivery.isoCountryCode}*/}
              {/*  </p>*/}
              {/*  {*/}
              {/*    Link !== undefined*/}
              {/*      ? (*/}
              {/*        <Link route="cartAddress" params={{ lang: routerLang }}>*/}
              {/*          <a className="link-edit">{t('cart:page.resume.modifier')}</a>*/}
              {/*        </Link>*/}
              {/*      ) : (<a href={`${urlLang}/cart/address`} className="link-edit">{t('cart:page.resume.modifier')}</a>)*/}
              {/*  }*/}
              {/*</div>/!* <!-- /.address --> *!/*/}
            </div>
          )}
        </div>{ /* <!--/.widget-order-sum --> */ }
      </aside>
    );
  }

  static contextType = NSContext;
}

CartResumeCustom.contextType = NSContext

export default withI18next()(CartResumeCustom);
