import React from 'react';
import ModalR from 'react-responsive-modal';
import {NSProductCard, NSDrawStars, NSProductStock} from "aqlrc";
import {withI18next} from "../lib/withI18n";

/**
 * NSProductCard - Affiche une vignette produit
 * @prop type: Type de valeur (data | id | code)
 * @prop value: Valeur en fonction de son type
 * @prop product: Objet produit
 * @prop gridDisplay: Rendu en grille (bool) sinon en liste
 * @prop includeCss: Inclure le CSS (bool)
 *
 * type="data" & value="objet_produit"
 * type="id" & value="id_produit"
 * type="code" & value="code_produit"
 *
 * @return {React.Component}
 */
export class ProductCard extends NSProductCard {

  render() {
    const {
      from, gridDisplay, gNext, includeCss, t, type, value
    } = this.props;
    const Head = (gNext && gNext.Head) || undefined;
    const Link = (gNext && gNext.Link) || undefined;
    const { openModal, product, selectedQty } = this.state;
    const { props } = this.context;
    if (!props) { return null; }
    const {
      lang, query, routerLang, taxDisplay, themeConfig, urlLang
    } = props;
    if (!product || !product._id) {
      return <p>{ t('product-card:load_error') } ({type}: {value})</p>;
    }

    // On affiche pas le produit dans le cas où il n'aurait pas de canonical hors contexte catégorie
    if (from !== 'category' && !product.canonical) return (<p>{ t('product-card:canonical_error') } (_id: {product._id})</p>);

    let canonical = null;
    let prdUrl = null;
    if (from === 'category' || !product.canonical) {
      prdUrl = `${urlLang}/${query._slug}/${product.slug[lang]}`;
    } else {
      prdUrl = product.canonical;
      canonical = product.canonical.match(/\/?([a-z]{2})?\/([0-9a-z-_\/]*)\/([0-9a-z-_]*)/i);
    }

    const average = (product.reviews && product.reviews.average) ? product.reviews.average : 0;
    const reviews_nb = (product.reviews && product.reviews.reviews_nb) ? product.reviews.reviews_nb : 0;

    // replace original product datas for default variant
    if (this.hasVariants(product) && product.code !== this.getDefaultVariantValue(product).code) {
      this.setState({
        product : {
          ...product,
          ...this.getDefaultVariantValue(product),
          _id : product._id
        }
      });
    }

    let imgDefault = `/images/products/196x173/no-image/${product.slug[lang]}.jpg`;
    let imgAlt = '';
    if (product && product.images) {
      const foundImg = product.images.find((img) => img.default);
      if (foundImg) {
        imgDefault = foundImg._id !== 'undefined' ? `/images/${product.selected_variant ? 'productsVariant' : 'products'}/196x173/${foundImg._id}/${product.slug[lang]}${foundImg.extension}` : imgDefault;
        imgAlt     = foundImg.alt || imgAlt;
      } else if (product.images[0]) {
        imgDefault = product.images[0]._id !== 'undefined' ? `/images/${product.selected_variant ? 'productsVariant' : 'products'}/196x173/${product.images[0]._id}/${product.slug[lang]}${product.images[0].extension}` : imgDefault;
        imgAlt     = product.images[0].alt || imgAlt;
      }
    }

    // Pictos
    const pictos = [];
    if (product.pictos) {
      product.pictos.forEach((picto) => {
        if (pictos.find((p) => p.location === picto.location) !== undefined) {
          pictos.find((p) => p.location === picto.location).pictos.push(picto);
        } else {
          const cardinals = picto.location.split('_');
          const style = { top: 0, left: 0 };
          if (cardinals.includes('RIGHT')) {
            style.left = 'inherit';
            style.right = 0;
          }
          if (cardinals.includes('BOTTOM')) {
            style.top = 'inherit';
            style.bottom = 0;
          }
          if (cardinals.includes('CENTER')) {
            style.left = '50%';
            style.transform = 'translate(-50%, 0)';
          }
          if (cardinals.includes('MIDDLE')) {
            style.top = '50%';
            style.transform = 'translate(0, -50%)';
          }
          pictos.push({ location: picto.location, style, pictos: [picto] });
        }
      });
    }

    return (
      <>
        {
          includeCss ? (
            Head !== undefined ? (
              <Head>
                <link rel="stylesheet" href="/static/css/product-card.css" />
              </Head>
            ) : (<link rel="stylesheet" href="/static/css/product-card.css" />)
          ) : null
        }
        {
          gridDisplay ? (
            <div className="product product--vertical" itemProp="itemListElement" itemScope itemType="http://schema.org/Product">
              <div className="product__image pointer">
                <div className="nbb">
                  {
                    pictos ? pictos.map((picto) => (
                      <div className="product_picto" style={picto.style} key={picto.location + Math.random()}>
                        {
                          picto.pictos && picto.pictos.map((p) => <img src={`/images/picto/32x32-70/${p.pictoId}/${p.image}`} alt={p.title} title={p.title} key={p._id} />)
                        }
                      </div>
                    )) : ''
                  }
                  <img onClick={(e) => this.changePage(e, prdUrl)} itemProp="image" src={imgDefault} alt={imgAlt} loading="lazy" />
                </div>
              </div>
              <div className="product__content">
                <div className="product__entry">
                  {
                    Link !== undefined ? (
                      <Link
                        route="product" params={
                        from === 'category' || !canonical ? {
                          lang    : query.lang,
                          _slug   : query._slug.split('/'),
                          product : product.slug[lang]
                        } : {
                          lang    : canonical[1],
                          _slug   : canonical[2].split('/'),
                          product : canonical[3]
                        }
                      }
                      >
                        <a>
                          <h4 itemProp="name" style={{ height: '48px', overflow: 'hidden', color: '#4A4A4A' }}>
                            {product.name}
                          </h4>
                        </a>
                      </Link>
                    ) : (
                      <a href={prdUrl}>
                        <h4 itemProp="name" style={{ height: '48px', overflow: 'hidden', color: '#4A4A4A' }}>
                          {product.name}
                        </h4>
                      </a>
                    )
                  }

                  {
                    product.description2 && product.description2.text
                    && (
                      <h6 onClick={(e) => this.changePage(e, prdUrl)} dangerouslySetInnerHTML={{ __html: product.description2.text }} />
                    )
                  }

                  {/*<div className="product--status" style={themeConfig && themeConfig.reviews !== undefined && themeConfig.reviews === false ? { display: 'none' } : {}}>*/}
                  {/*  <div className="rating">*/}
                  {/*    <div className="rating-split align-star" onClick={(e) => this.changePage(e, prdUrl, '#reviews')}>*/}
                  {/*      <NSDrawStars*/}
                  {/*        average={average} size="16"*/}
                  {/*        src="/static/images/sprite/ico-star-full@2x.png"*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*    <span>*/}
                  {/*                              {*/}
                  {/*                                reviews_nb*/}
                  {/*                                  ? `${average}/5 `*/}
                  {/*                                  : ''*/}
                  {/*                              }*/}
                  {/*      {*/}
                  {/*        reviews_nb > 1*/}
                  {/*          ? `(${reviews_nb} ${t('product-card:avis')})`*/}
                  {/*          : `(${reviews_nb} ${t('product-card:un_avis')})`*/}
                  {/*      }*/}
                  {/*                          </span>*/}
                  {/*  </div>/!* <!-- /.rating --> *!/*/}
                  {/*</div>/!* <!-- /.product--status --> *!/*/}

                  <p onClick={(e) => this.changePage(e, prdUrl)} className="description" itemProp="description" style={{ maxHeight: '57px', overflow: 'hidden' }}>
                    {product.description2 ? product.description2.title : ''}
                  </p>
                </div>

                <div className="product__aside">
                  <div className="product-stock">
                    <NSProductStock stock={product.stock} />
                  </div>
                  <div className="product-price" itemType="http://schema.org/Offers" itemScope itemProp="price">
                    <del hidden={product.price.et.special === undefined}><span>{product.price[taxDisplay].normal ? product.price[taxDisplay].normal.aqlRound(2) : '0.00'}</span>€ <sub>{t(`common:price.${taxDisplay}`)}</sub></del>

                    <strong>
                      <span>{product.price.et.special !== undefined ? product.price[taxDisplay].special.aqlRound(2) : (product.price[taxDisplay].normal ? product.price[taxDisplay].normal.aqlRound(2) : '0.00')  }</span>€ <sub>{t(`common:price.${taxDisplay}`)}</sub>
                    </strong>
                  </div>
                </div>{/* <!-- /.product__aside --> */}


                <div className="product__actions">
                  {
                   product.attributes.find((attrib) => attrib.code === 'personnalisation' && attrib.value === true) ?
                        Link !== undefined ? (
                            <Link
                              route="product" params={
                              from === 'category' || !canonical ? {
                                lang    : query.lang,
                                _slug   : query._slug.split('/'),
                                product : product.slug[lang]
                              } : {
                                lang    : canonical[1],
                                _slug   : canonical[2].split('/'),
                                product : canonical[3]
                              }
                            }
                            >
                            <a className="btn btn--red btn-cart">
                              <i className="ico-edit-white" />
                            <span itemProp="name" style={{ overflow: 'hidden', color: '#4A4A4A' }}>

                            </span>
                              </a>
                            </Link>
                          )
                          : (
                            <a href={prdUrl}>  <i className="ico-edit-white" />

                      <span itemProp="name" style={{ overflow: 'hidden', color: '#4A4A4A' }}>

                      </span>
                            </a>
                          ) :
                    (!product.stock || (product.stock && product.stock.status !== 'epu')) && (!product.attributes.find((attrib) => attrib.code === 'personnalisation' && attrib.value === true)) && (
                        <div className="product__actions_qty">
                         {
                           product.stock && product.stock.status !== 'epu'
                           && (
                             <div className="form__row form__row--flex">
                               <label htmlFor={`field-qty-${product._id}`} className="form__label">{t('product-card:quantite')}</label>
                                 <input  type="text" className="field" name={`field-qty-${product._id}`} id={`field-qty-${product._id}`} onChange={(e) => this.setState({ selectedQty: Number(e.target.value) })} value={selectedQty} />
                             </div>
                           )
                         }
                          <button onClick={product.type === 'virtual' && (product.price[taxDisplay].special === 0 || product.price[taxDisplay].normal === 0) ? this.downloadVirtual : (product.type === 'bundle' ? this.onOpenModal : this.addToCart)} type="button" className="btn btn--red btn-cart" aria-label={t('product-card:ajoutPanier')}>
                            <span className="material-symbols-outlined">local_mall</span>
                            <span>{product.type === 'virtual' && (product.price[taxDisplay].special === 0 || product.price[taxDisplay].normal === 0) ? t('product-card:download') : (product.type === 'bundle' ? t('product-card:composer') : t('product-card:ajoutPanier'))}</span>
                          </button>
                       </div>
                    )
                  }
                </div>{/* <!-- /.product__actions --> */}
              </div>{/* <!-- /.product__content --> */}
            </div>
          ) : (
            <div className="product product--horizontal" id={`id${product._id}`} itemProp="itemListElement" itemScope itemType="http://schema.org/Product">
              <div className="product__image pointer">
                <div className="nbb">
                  {
                    pictos ? pictos.map((picto) => (
                      <div className="product_picto" style={picto.style} key={picto.location + Math.random()}>
                        {
                          picto.pictos && picto.pictos.map((p) => <img src={`/images/picto/32x32-70/${p.pictoId}/${p.image}`} alt={p.title} title={p.title} key={p._id} />)
                        }
                      </div>
                    )) : ''
                  }
                  <img
                    onClick={(e) => this.changePage(e, prdUrl)} itemProp="image" src={imgDefault} alt={imgAlt} loading="lazy"
                  />
                </div>
              </div>
              <div className="product__content">
                <div className="product__entry">
                  {
                    Link !== undefined ? (
                      <Link
                        route="product" params={
                        from === 'category' || !canonical ? {
                          lang    : query.lang,
                          _slug   : query._slug.split('/'),
                          product : product.slug[lang]
                        } : {
                          lang    : canonical[1],
                          _slug   : canonical[2].split('/'),
                          product : canonical[3]
                        }
                      }
                      >
                        <a>
                          <h4 itemProp="name" style={{ overflow: 'hidden', color: '#4A4A4A' }}>
                            {product.name}
                          </h4>
                        </a>
                      </Link>
                    ) : (
                      <a href={prdUrl}>
                        <h4 itemProp="name" style={{ overflow: 'hidden', color: '#4A4A4A' }}>
                          {product.name}
                        </h4>
                      </a>
                    )
                  }
                  {/*<div className="product--status" style={themeConfig && themeConfig.reviews !== undefined && themeConfig.reviews === false ? { display: 'none' } : {}}>*/}
                  {/*  <div className="rating pointer" onClick={(e) => this.changePage(e, prdUrl, '#reviews')}>*/}
                  {/*    <div className="rating-split align-star">*/}
                  {/*      <NSDrawStars*/}
                  {/*        average={average} size="13"*/}
                  {/*        src="/static/images/sprite/ico-star-full@2x.png"*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*    <span>*/}
                  {/*                              {*/}
                  {/*                                reviews_nb*/}
                  {/*                                  ? `${average}/5 `*/}
                  {/*                                  : ''*/}
                  {/*                              }*/}
                  {/*      {*/}
                  {/*        reviews_nb > 1*/}
                  {/*          ? `(${reviews_nb} ${t('product-card:avis')})`*/}
                  {/*          : `(${reviews_nb} ${t('product-card:un_avis')})`*/}
                  {/*      }*/}
                  {/*                          </span>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {
                    product.description2 && product.description2.text && (<h6 onClick={(e) => this.changePage(e, prdUrl)} dangerouslySetInnerHTML={{ __html: product.description2.text }} />)
                  }
                </div>
                <div className="product__aside">

                  <NSProductStock stock={product.stock} />

                  <div className="product-price">
                    <del hidden={product.price.et.special === undefined}><span>{product.price[taxDisplay].normal ? product.price[taxDisplay].normal.aqlRound(2) : '0.00'}</span>€ <sub>{t(`common:price.${taxDisplay}`)}</sub></del>
                    <strong>
                      <span>{(product.price.et.special !== undefined ? product.price[taxDisplay].special : (product.price[taxDisplay].normal ? product.price[taxDisplay].normal : 0)).aqlRound(2)}</span>€ <sub>{t(`common:price.${taxDisplay}`)}</sub>
                    </strong>
                  </div>

                  <div className="product-qty">
                    {
                      product.stock && product.stock.status !== 'epu'
                      && (
                        <div className="form__row form__row--flex">
                          <label htmlFor={`field-qty-${product._id}`} className="form__label">{t('product-card:quantite')}</label>
                          <div className="form__controls qty-controls">
                            <button type="button" className="btn-qty-change btn-decrement" onClick={this.decrementQty}>-</button>

                            <input  type="text" className="field" name={`field-qty-${product._id}`} id={`field-qty-${product._id}`} onChange={(e) => this.setState({ selectedQty: Number(e.target.value) })} value={selectedQty} />

                            <button type="button" className="btn-qty-change btn-increment" onClick={this.incrementQty}>+</button>
                          </div>
                        </div>
                      )
                    }
                  </div>

                  <div>
                    {
                      product.attributes.find((attrib) => attrib.code === 'personnalisation' && attrib.value === true) ?
                       Link !== undefined ? (
                        <Link
                          route="product" params={
                          from === 'category' || !canonical ? {
                            lang    : query.lang,
                            _slug   : query._slug.split('/'),
                            product : product.slug[lang]
                          } : {
                            lang    : canonical[1],
                            _slug   : canonical[2].split('/'),
                            product : canonical[3]
                          }
                        }
                        >
                          <a className="btn btn--red btn-cart">
                            <span itemProp="name" style={{ overflow: 'hidden', color: '#4A4A4A' }}>
                            Personnaliser
                            </span>
                          </a>
                        </Link>
                      )
                      : (
                      <a href={prdUrl}>
                      <span itemProp="name" style={{ overflow: 'hidden', color: '#4A4A4A' }}>
                        Personnaliser
                      </span>
                      </a>
                      ) :
                      (!product.stock || (product.stock && product.stock.status !== 'epu')) && (
                        <button onClick={product.type === 'virtual' && (product.price[taxDisplay].special === 0 || product.price[taxDisplay].normal === 0) ? this.downloadVirtual : (product.type === 'bundle' ? this.onOpenModal : this.addToCart)} type="button" className="btn btn--red btn-cart" aria-label={t('product-card:ajoutPanier')}>
                          <span className="material-symbols-outlined">local_mall</span>
                          <span>{product.type === 'virtual' && (product.price[taxDisplay].special === 0 || product.price[taxDisplay].normal === 0) ? t('product-card:download') : (product.type === 'bundle' ? t('product-card:composer') : t('product-card:ajoutPanier'))}</span>
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <ModalR
          animationDuration={0} classNames={{ modal: 'popup__container', overlay: 'popup active' }}
          open={openModal} onClose={this.onCloseModal} center showCloseIcon={false}
        >
          {
            product.type === 'bundle' ? (
              <div className="modifier-popup__wrap">
                <button type="button" className="btn btn-link modifier-popup__close" onClick={this.onCloseModal} />
                <h3 className="modifier-popup__header">{t('product-card:composeMenu')}</h3>
                <form ref={(form) => this.formMenu = form}>
                  <div className="form__body">
                    <NSBundleProduct product={product} t={t} />

                    <div className="product-price">
                      <strong>{(product.price.ati.normal + this.state.bundleGlobalModifierPrice || 0).aqlRound(2)} €</strong>
                    </div>{/* <!-- /.price --> */}
                  </div>{/* <!-- /.popup__body --> */}
                  <div className="form-footer">
                    <button type="button" className="btn btn--red btn-cart" onClick={this.addToCart} aria-label={t('product-card:ajoutPanier')}>
                      <i className="ico-shopping-cart-white" />
                      <span>{t('product-card:ajoutPanier')}</span>
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <>
                <h3 className="popup__title">{t('product-card:productAdded')} :</h3>
                <div className="popup__body">
                  <div className="product-simple">
                    <figure className="product__image">
                      <img src={imgDefault} alt={imgAlt} width="256" height="197" />
                    </figure>

                    <h4 className="product__title">{this.state.selectedQty} x {product.name}</h4>

                    <div className="product__actions">
                      <button type="button" className="btn btn--with-icon btn--red" onClick={this.onCloseModal}>
                        {t('product-card:continueShopping')}
                      </button>

                      {
                        Link !== undefined ? (
                          <Link route="cart" params={{ lang: routerLang }}>
                            <a className="btn btn--with-icon btn--red">
                              {t('product-card:viewCart')}
                            </a>
                          </Link>
                        ) : (
                          <a href={`${urlLang}/cart`} className="btn btn--with-icon btn--red">
                            {t('product-card:viewCart')}
                          </a>
                        )
                      }
                    </div>
                  </div>
                </div>
              </>
            )
          }
        </ModalR>
      </>
    );
  }

}
export default withI18next(['login'])(ProductCard);
