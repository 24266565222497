import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {NSToast, getAPIUrl} from 'aqlrc';
import PropTypes from 'prop-types';
import {withI18next} from '../lib/withI18n';

const propTypes = {
    theme  : PropTypes.string,
    size   : PropTypes.string,
    version: PropTypes.string,
    badge  : PropTypes.string
};

const defaultProps = {
    theme  : "light",
    size   : "normal",
    version: "2.0",
    badge  : "bottomright"
};

let readyCheck;
let versionCap = "2.0";
let form;
let invalid = false;
let token;
let sitekey;

class ModuleRecaptcha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: this.props.theme || "light",
            size: this.props.size || "normal",
            version: this.props.version ||  "2.0",
            badge: this.props.badge || "none",
            ready: typeof window !== "undefined",
            captchaScript: false
        };
    }
    
    async componentDidMount() {
        //console.log("componentDidMount")
        if(this.state.version == "3.0"){
            versionCap = "3.0";
        }else if(this.state.version == "2.1"){
            versionCap = "2.1";
        }else{
            versionCap = "2.0";
        }
        if(typeof this.state.ready !== "undefined" && this.state.ready == false){
            if(typeof window !== "undefined") {
                this.setState({
                    ready: true,
                    ...this.state
                });
            }
        } else {
            readyCheck = setInterval(this._updateReadyState.bind(this), 100);
            this.setState({
                ready: false,
                ...this.state
            });
        }
        this.getPublicKeyAndScript();
        this.getForm();
    }

    getPublicKeyAndScript = async function(){
        try {
            const config = await axios.get(`${getAPIUrl()}v2/recaptcha/getPublicConfig`);
            if(versionCap == "2.0" || versionCap == "2.1"){
                sitekey = config.data.v2;
            }else{
                sitekey = config.data.v3;
            }
            this.setState({
                ...this.state
            });
        } catch (err) {
            throw err;
        }
    }

    getForm = function(){
        const element = document.getElementById("captchaDiv");
        form = element.parentNode;
        // On prend le <form> parent le plus proche
        while (form.tagName !== 'FORM'  && form.tagName !== 'HTML'){
            form = form.parentNode; 
        }
        const oldOnSubmit = form.onsubmit;
        const { t } = this.props; // translation
        form.onsubmit = async function (event) {
            event.preventDefault();
            token = "";
            if(versionCap == "2.0"){
                token = grecaptcha.getResponse();
            }else if(versionCap == "2.1"){
                await grecaptcha.execute();
                const waiting = async function(){
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            resolve();
                        }, 500);
                    });
                }
                await waiting();
                token = await grecaptcha.getResponse();
            } else if(versionCap == "3.0"){
                await grecaptcha.execute(sitekey, {action: 'homepage'}).then(function(tokenReCaptcha){
                    token = tokenReCaptcha;
                });
            }
            if(token != ""){
                try {
                    const answer = await axios.post(`${getAPIUrl()}v2/recaptcha/checkToken`, {
                        token: token,
                        version: versionCap
                    });
                    if(answer.data.success == true){
                        oldOnSubmit(event);
                        grecaptcha.reset();
                        return true;
                    }else{
                        NSToast.error(t('invalid'));
                        return false;
                    }
                } catch (err) {
                    throw err;
                }
            }else{
                if(invalid == false){
                    NSToast.error(t('incomplete'));
                    return false;
                }else{
                    NSToast.error(t('invalid'));
                    return false;
                }
            }
        }
        this.setState({
            form: form,
            ...this.state
        });
    }

    _updateReadyState() {
        if (typeof window !== 'undefined') {
            this.setState({
                ready: true,
                ...this.state
            });
            clearInterval(readyCheck);
        }
    }

    async componentDidUpdate(_, prevState) {
        if(this.state.ready && sitekey && this.state.captchaScript == false){
            // we load the captcha this and we put the callback function to window object
            const script = document.createElement('script');
            if(versionCap == "3.0"){
                script.src = `https://www.google.com/recaptcha/api.js?onload=reCAPTCHA&render=${sitekey}`;
            }else{
                script.src = "https://www.google.com/recaptcha/api.js?onload=reCAPTCHA&render=explicit";
            }
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            this.state.captchaScript = true;
            this.setState({
                captchaScript: true,
                ...this.state
            });
            const { theme, size, badge} = this.state;
            window.reCAPTCHA = function(){
                const expiredCallback = function(error){
                    invalid = true;
                }
                const errorCallback = function(error){
                    throw error;
                }
                const verifyCallback = async function(token){
                    //console.log(token);
                }
                if(versionCap == "2.0"){
                    window.grecaptcha.ready(function() {
                        console.log('reCAPTCHA v2.0 loaded');
                        grecaptcha.render(document.getElementById("captchaDiv"), {
                            "expired-callback": expiredCallback,
                            "error-callback"  : errorCallback,
                            'callback' : verifyCallback,
                            'sitekey'  : sitekey,
                            'theme'    : theme,
                            "size"     : size
                        });
                    });
                }else if(versionCap == "2.1"){
                    console.log("KAKAKKAKA")
                    window.grecaptcha.ready(function() {
                        console.log('reCAPTCHA v2.1 loaded');
                        grecaptcha.render(document.getElementById("captchaDiv"), {
                            "expired-callback": expiredCallback,
                            "error-callback"  : errorCallback,
                            'callback' : verifyCallback,
                            'sitekey'  : sitekey,
                            'theme'    : theme,
                            "size"     : "invisible",
                            "badge"    : badge
                        });
                    });
                }else if(versionCap == "3.0"){
                    window.grecaptcha.ready(function() {
                        console.log('reCAPTCHA v3.0 loaded');
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState({
            ready: false,
            ...this.state
        });
        //console.log("componentWillUnmount");
        clearInterval(readyCheck);
    }

    render() {
        return (
            <div id="captchaDiv"></div>
        );
    }
}

ModuleRecaptcha.contextTypes = {getPageState: PropTypes.func};
ModuleRecaptcha.propTypes = propTypes;
ModuleRecaptcha.defaultProps = defaultProps;

export default withI18next(['modules/recaptcha-aquila/recaptcha-aquila'])(ModuleRecaptcha);
