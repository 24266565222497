import React from 'react';
import { NSContext, NSAddressMultiple} from 'aqlrc';
import { withI18next } from 'lib/withI18n';

export class AddressMultiple extends NSAddressMultiple {

  render() {
    const { gNext, t } = this.props;
    const {
      address, countries, editMode, useSameAddress, user
    } = this.state;
    const { props, state } = this.context;
    if (!props || !state) { return null; }
    const {
      cmsLegalTxt, routerLang, urlLang
    } = props;
    const Router = (gNext && gNext.Router) || undefined;
    /* if (user.addresses.length === 0 && editMode && !address.firstname) {
        this.setState({
            address : {
                firstname            : user.firstname || '',
                lastname             : user.lastname || '',
                phone                : user.phone || '',
                companyName          : user.company ? user.company.name : '',
                idx_delivery_address : '',
                idx_billing_address  : ''
            }
        });
    } */
    const index_billing_address = user.addresses[user.billing_address] ? user.billing_address : 0;
    const index_delivery_address = user.addresses[user.delivery_address] ? user.delivery_address : 0;
    return (
      <div className="content content--left">
        {
          editMode === false
            ? (
              <div className="section__content">
                <div className="form form-shipping">
                  <form onSubmit={(e) => this.submitAddresses(e, this.submitAddressesCallback)}>
                    <div style={{ height: '38px', marginBottom: '10px' }}>
                      <button type="button" className="btn btn--grey float-right" onClick={() => this.resetForm('#form-address')}>{t('addresses:list.ajouter')}</button>
                    </div>
                    {user && user.addresses && user.addresses.length > 0 && (
                      <div className="addresses">
                        <div className="address">
                          <h3>{t('addresses:list.adresseFacturation')}</h3>
                          <p>{user.addresses[index_billing_address].firstname} {user.addresses[index_billing_address].lastname}</p>
                          <p>{user.addresses[index_billing_address].line1}</p>
                          <p>{user.addresses[index_billing_address].line2}</p>
                          <p>{user.addresses[index_billing_address].zipcode} {user.addresses[index_billing_address].city} {user.addresses[index_billing_address].isoCountryCode}</p>
                          <div style={{ marginTop: '15px' }}>
                            <button type="button" className="btn btn--grey" onClick={() => this.setState({ editMode: true, address: { ...user.addresses[index_billing_address] }, index: index_billing_address })}>
                              {t('addresses:list.modifier')}
                            </button>
                          </div>
                        </div>
                        {(this.isInCheckout() && state.cart && state.cart.orderReceipt.method === 'withdrawal') && (
                          <div className="address">
                            <h3>{t('addresses:list.adresseLivraison')}</h3>
                            <p>{state.cart.addresses.delivery.companyName}</p>
                            <p>{state.cart.addresses.delivery.line1}</p>
                            <p>{state.cart.addresses.delivery.line2}</p>
                            <p>{state.cart.addresses.delivery.zipcode} {state.cart.addresses.delivery.city} {state.cart.addresses.delivery.isoCountryCode}</p>
                          </div>
                        )}
                        {/*{(!this.isInCheckout() || !state.cart || state.cart.orderReceipt.method !== 'withdrawal') && (*/}
                        {/*  <div className="address">*/}
                        {/*    <h3>{t('addresses:list.adresseLivraison')}</h3>*/}
                        {/*    <p>{user.addresses[index_delivery_address].firstname} {user.addresses[index_delivery_address].lastname}</p>*/}
                        {/*    <p>{user.addresses[index_delivery_address].line1}</p>*/}
                        {/*    <p>{user.addresses[index_delivery_address].line2}</p>*/}
                        {/*    <p>{user.addresses[index_delivery_address].zipcode} {user.addresses[index_delivery_address].city} {user.addresses[index_delivery_address].isoCountryCode}</p>*/}
                        {/*    <div style={{ marginTop: '15px' }}>*/}
                        {/*      <button type="button" className="btn btn--grey" onClick={() => this.setState({ editMode: true, address: { ...user.addresses[index_delivery_address] }, index: index_delivery_address })}>*/}
                        {/*        {t('addresses:list.modifier')}*/}
                        {/*      </button>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*)}*/}
                        {
                          user.addresses && user.addresses.map((addr, index) => {
                            addr.idx_delivery_address = Number(user.delivery_address) === index ? user.delivery_address : '';
                            addr.idx_billing_address = Number(user.billing_address) === index ? user.billing_address : '';
                            return (
                              <div key={addr._id + index} hidden={user.delivery_address === index || user.billing_address === index} className="address">
                                <h3>{t('addresses:list.adresse')}</h3>
                                <p>{addr.firstname} {addr.lastname}</p>
                                <p>{addr.line1}</p>
                                <p>{addr.line2}</p>
                                <p>{addr.zipcode} {addr.city} {addr.isoCountryCode}</p>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                                  <div>
                                    <button type="button" className="btn btn--grey" onClick={() => this.handleEditMode(addr, index)}>
                                      {t('addresses:list.modifier')}
                                    </button>
                                    &nbsp;
                                    <button type="button" className="btn btn--grey" onClick={() => this.deleteAddress(index)}>
                                      {t('addresses:list.supprimer')}
                                    </button>
                                  </div>
                                  <p>
                                                                        <span style={{ cursor: 'pointer' }} onClick={() => this.useAddressAs(addr, index, 'idx_billing_address')}>
                                                                            {t('addresses:list.use_as_billing')}
                                                                        </span>
                                    <br />
                                    <span style={{ cursor: 'pointer' }} onClick={() => this.useAddressAs(addr, index, 'idx_delivery_address')}>
                                                                            {t('addresses:list.use_as_delivery')}
                                                                        </span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    )}{ /* <!--/.form__cols --> */ }

                    {typeof window !== 'undefined' && window.location.pathname.indexOf('/cart/') > -1 && (
                      <div className="form__actions text-right">
                        <div hidden className="address-toggle">
                          <div className="checkbox checkbox--medium checkbox--square-icon">
                            <input
                              onChange={(e) => this.setState({ useSameAddress: e.target.checked }, () => {
                                this.fuseAddresses();
                              })}
                              type="checkbox" name="field-same-address-both" id="field-same-address-both" checked={useSameAddress}
                            />
                            <label htmlFor="field-same-address-both">{t('addresses:list.both_addresses')}</label>
                          </div>{ /* <!--/.checkbox --> */ }
                        </div>{ /* <!--/.address-toggle --> */ }

                        <button className="btn btn--grey" style={{ float: 'left' }} onClick={() => { Router !== undefined ? Router.back() : window.history.back(); }} type="button">
                          {t('common:retour')}
                        </button>
                        <button type="submit" className="form__btn btn btn--red">{t('addresses:list.valider')}</button>
                      </div>
                    )}{ /* <!--/.form__actions --> */ }
                  </form>
                </div>{ /* <!--/.form form-shipping --> */ }
              </div>
            )
            : (
              <div className="section__content">
                <span style={{ display: 'none' }}>{(typeof window !== 'undefined' && window.location.pathname.indexOf('cart') > -1 && document.querySelector('#return-btn')) ? (editMode  ? document.querySelector('#return-btn').style.display = 'none' : document.querySelector('#return-btn').style.display = 'block') : ''}</span>
                <div className="form">
                  <form id="form-address" onSubmit={(e) => this.addAddress(e)}>

                    <div style={{ margin: '30px 0 30px 0' }}><button type="button" className="btn btn--grey float-right" onClick={() => this.setState({ editMode: false })}>{t('addresses:edit.retour')}</button></div>

                    <div className="form__body">
                      <div className="form__group form__group--shrink">
                        <div className="form__row form__row--flex align-right">
                          <span htmlFor="field-name-last" className="form__label"><strong>{t('addresses:edit.infosDe')} </strong></span>

                          <div className="form__controls">
                            <ul className="list-checkboxes list-checkboxes--flex">
                              <li>
                                <div className="checkbox checkbox--medium" onClick={(e) => this.handleAddressChange(e)}>
                                  <input
                                    type="checkbox" name="idx_billing_address" id="field-billing-address"
                                    defaultChecked={(address && address.idx_billing_address > -1 && address.idx_billing_address !== '') || Number(this.state.index) === Number(user.billing_address)}
                                    disabled={this.addressDisabled(user.billing_address, user.addresses)}
                                  />
                                  <label htmlFor="field-billing-address">{t('addresses:edit.facturation')}</label>
                                </div>{/* <!-- /.checkbox --> */}
                              </li>
                              <li>
                                <div className="checkbox checkbox--medium" onClick={(e) => this.handleAddressChange(e)}>
                                  <input
                                    type="checkbox" name="idx_delivery_address" id="field-delivery-address"
                                    defaultChecked={(address && address.idx_delivery_address > -1 && address.idx_delivery_address !== '') || Number(this.state.index) === Number(user.delivery_address)}
                                    disabled={this.addressDisabled(user.delivery_address, user.addresses)}
                                  />
                                  <label htmlFor="field-delivery-address">{t('addresses:edit.livraison')}</label>
                                </div>{/* <!-- /.checkbox --> */}
                              </li>
                            </ul>{/* <!-- /.list-checkboxes --> */}
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}
                        <div className="form__row form__row--flex align-right">
                          <span htmlFor="field-name-last" className="form__label"><strong>{t('addresses:edit.titre')}</strong><span>*</span></span>
                          <div className="form__controls">
                            <ul className="list-checkboxes list-checkboxes--flex">
                              <li>
                                <div className="checkbox checkbox--medium">
                                  <input hidden type="radio" name="civility" id="field-madame" value={1} defaultChecked={address.civility === 1} onClick={(e) => this.handleAddressChange(e)} />
                                  <label htmlFor="field-madame">{t('addresses:edit.mme')}</label>
                                </div>{/* <!-- /.checkbox --> */}
                              </li>
                              <li>
                                <div className="checkbox checkbox--medium">
                                  <input hidden type="radio" name="civility" id="field-monsieur" value={0} defaultChecked={address.civility === 0} onClick={(e) => this.handleAddressChange(e)} />
                                  <label htmlFor="field-monsieur">{t('addresses:edit.mr')}</label>
                                </div>{/* <!-- /.checkbox --> */}
                              </li>
                            </ul>{/* <!-- /.list-checkboxes --> */}
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-name" className="form__label">{t('addresses:edit.nom')}<span>*</span></label>
                          <div className="form__controls">
                            <input type="text" className="field" name="lastname" id="field-name" value={address.lastname} onChange={(e) => this.handleAddressChange(e)} required />
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-name-last" className="form__label">{t('addresses:edit.prenom')}<span>*</span></label>
                          <div className="form__controls">
                            <input type="text" className="field" name="firstname" id="field-name-last" value={address.firstname} onChange={(e) => this.handleAddressChange(e)} required />
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-company" className="form__label">{t('addresses:edit.societe')}</label>
                          <div className="form__controls">
                            <input type="text" className="field" name="companyName" id="field-company" value={address.companyName} onChange={(e) => this.handleAddressChange(e)} />
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form-spacings" />{/* <!-- /.form-spacings --> */}

                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-address-shiping" className="form__label">{t('addresses:edit.adresse')}<span>*</span></label>
                          <div className="form__controls">
                            <input type="text" className="field" name="line1" id="field-address-shiping" value={address.line1} onChange={(e) => this.handleAddressChange(e)} required />
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-address-shiping-2" className="form__label">{t('addresses:edit.adresse2')}</label>
                          <div className="form__controls">
                            <input type="text" className="field" name="line2" id="field-address-shiping-2" value={address.line2} onChange={(e) => this.handleAddressChange(e)} />
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-zip" className="form__label">{t('addresses:edit.codePostal')}<span>*</span></label>
                          <div className="form__controls">
                            <input type="text" className="field field--small" name="zipcode" id="field-zip" value={address.zipcode} onChange={(e) => this.handleAddressChange(e)} required />
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-city" className="form__label">{t('addresses:edit.ville')}<span>*</span></label>
                          <div className="form__controls">
                            <input type="text" className="field" name="city" id="field-city" value={address.city} onChange={(e) => this.handleAddressChange(e)} required />
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-country" className="form__label">{t('addresses:edit.pays')}<span>*</span></label>
                          <div className="form__controls">
                            <div className="select__controls">
                              <select
                                name="country"
                                id="field-country"
                                className="field"
                                value={address.isoCountryCode}
                                onChange={(e) => this.handleAddressChange(e)}
                                required
                              >
                                <option value="">-</option>
                                {
                                  countries.map((country) => (
                                    <option key={country._id} value={country.code}>
                                      {country.name}
                                    </option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>{/* <!-- /.form__controls --> */}
                        </div>
                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-phone" className="form__label">{t('addresses:edit.telPortable')}<span>*</span></label>
                          <div className="form__controls">
                            <input type="text" className="field" name="phone_mobile" id="field-phone" value={address.phone_mobile} onChange={(e) => this.handleAddressChange(e)} required />
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}

                        <div className="form__row form__row--flex align-right">
                          <label htmlFor="field-phone-home" className="form__label">{t('addresses:edit.telFixe')}</label>
                          <div className="form__controls">
                            <input type="text" className="field" name="phone" id="field-phone-home" value={address.phone} onChange={(e) => this.handleAddressChange(e)} />
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}
                        <div className="form__row form__row--flex align-right">
                          <div className="form__controls form__controls-password">
                            <button type="submit" className="btn btn--red">{t('addresses:edit.confirmer')}</button>
                          </div>{/* <!-- /.form__controls --> */}
                        </div>{/* <!-- /.form__row --> */}
                        <div className="form__entry form__entry-spaced-left">
                          <div className="form__hint">
                            <p>*{t('addresses:edit.champsObligatoires')}</p>
                          </div>{/* <!-- /.form__hint --> */}
                          <div>
                            <div dangerouslySetInnerHTML={{ __html: cmsLegalTxt.content || '' }} />
                          </div>
                        </div>{/* <!-- /.form__entry form__entry-spaced-left --> */}
                      </div>{/* <!-- /.form__group --> */}
                    </div>{/* <!-- /.form__body --> */}
                  </form>
                </div>{/* <!-- /.form --> */}
              </div>
            )
        }
      </div>/* <!--/.content --> */
    );
  }

  static contextType = NSContext;
}
export default withI18next(['common'])(AddressMultiple);
