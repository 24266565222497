import React from 'react';
import {
    _NSconfig
} from 'aqlrc'; // Import depuis AQLRC de la config par défaut
import BlockSlider from './BlockSlider';
import Menu from './Menu.js';
import BlogArticles from './BlogArticles.js';
import LoginCustom from './LoginCustom';
import AddressMultiple from "./AddressMultiple";
import CartResumeCustom from "./CartResumeCustom";
import ProductCard from "./ProductCard";
import CartCounter from './CartCounter';// Ligne ajouté pour référencer le composant custom

// On surcharge la config par défaut de AQLRC si besoin
// A noter que <Link> et <CMS> sont déjà gérés directement dans le composant CMS, il faut utiliser respectivement "ns-link" et "ns-cms"
// A garder dans l'ordre alphabétique en fonction du nom du composant SVP
export default {
    ..._NSconfig,
    'ns-block-slider' : <BlockSlider />,
    'ns-menu':  <Menu />,
    'ns-blog-articles':  <BlogArticles />,
    'ns-btn-login' : <LoginCustom />,
    'ns-cart-counter' : <CartCounter />,
    'ns-btn' : <LoginCustom />,
    'ns-address-multiple' : <AddressMultiple/>,
    'ns-cart-resume' : <CartResumeCustom/>,
    'ns-product-card' : <ProductCard/>

};
