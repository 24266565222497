import React from 'react';
import { logoutUser, NSContext, NSToast } from 'aqlrc';
import { Link } from 'routes';
import { withI18next } from 'lib/withI18n';

class LoginCustom extends React.Component {
  logout = async () => {
    // Déconnexion de l'utilisateur
    try {
      window.localStorage.removeItem('cart_id');
      window.localStorage.removeItem('comment');
      await logoutUser();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        NSToast.error(err.response.data.message);
      } else {
        NSToast.error('common:error_occured');
        console.error(err);
      }
    }
  }

  render () {
    const { t } = this.props;
    const { props } = this.context;
    if (!props) { return null; }
    const { routerLang, user } = props;
    let route = 'auth';
    let client = false;
    if (user && user._id) {
      client = true;
    }
    if (user && !client) {
      route = '/shop/order';
    } else {
      route = 'account';
    }
    return (
    <li>
      <div className="listCustom">
        <Link route={ route } params={ { lang: routerLang } }>
          <a><span className="material-symbols-outlined ">account_circle</span><div>{ user ? t('common:monCompte') : t('common:connecter') }</div></a>
        </Link>
        {
          user ?
            <Link route={'home'} params={ { lang: routerLang } }>
              <a onClick={this.logout}><span className="material-symbols-outlined logout">logout</span><div>{ t('common:deconnexion') }</div></a>
            </Link>
          : null
        }
      </div>
    </li>
    );
  }
}

LoginCustom.contextType = NSContext

export default withI18next()(LoginCustom);
