import React from 'react';
import { NSMenu } from 'aqlrc';
import { Link } from '../routes';

/**
 * NSMenu - Affiche le bloc du menu correspondant au code passé en prop (ns-code)
 * @prop ns-code: (string) nom du menu à afficher
 * @prop classnav: (string) Classe CSS balise <nav>
 * @prop classnavul: (string) Classe CSS balise <ul>
 * @prop classnavulli: (string) Classe CSS balise <li>
 * @prop classnavulliactive: (string) Classe CSS balise <li> active
 * @prop classnavullia: (string) Classe CSS balise <a>
 * @prop classnavulliaactive: (string) Classe CSS balise <a> active
 * @prop classbtnmobile: (string) Classe CSS du burger en mobile
 * @prop classbtnactivemobile: (string) Classe CSS du burger actif en mobile
 * @prop classnavactivemobile: (string) Classe CSS du balise <nav> active en mobile
 * @prop levelmax: (string) Niveau max de récursivité du menu
 * @prop css: (string) CSS par défaut ("0" = non)
 * @return {React.Component}
 */

class Menu extends NSMenu {
    componentDidMount = () => {
        const {
            classbtnmobile, classbtnactivemobile, classnav, classnavactivemobile
        } = this.props;
        if (typeof window !== 'undefined' && document.querySelector(`.${classbtnmobile}`)) {
            document.querySelector(`.${classbtnmobile}`).onclick = () => {
                if (classbtnactivemobile) {
                    document.querySelector(`.${classbtnmobile}`).classList.toggle(classbtnactivemobile);
                }
                if (classnavactivemobile) {
                    document.querySelector(`.${classnav}`).classList.toggle(classnavactivemobile);
                }
            };

            window.addEventListener('routeChange', this.routeChangeListener);
        }
    }

    recursive(menu, l, slugs = []) {
        const {
            gNext, classnavulli, classnavulliactive, classnavullia, classnavulliaactive, levelmax
        } = this.props;
        const Link = (gNext && gNext.Link) || undefined;
        const {
            lang, routerLang, urlLang, currentUrl, user
        } = this.context.props;
        let client = false;
        if (user && user._id) {
            client = true;
        }
        if (levelmax && l >= levelmax) return '';
        return menu.children.map((section) => {
            const arrowChildren = section.children !== undefined && section.children.length ? (
                <span style={{
                    display : 'inline-block', height : '0', width : '0', borderTop : '6px solid', borderRight : '3px solid transparent', borderLeft : '3px solid transparent'
                }}
                />
            ) : null;

            // Gestion du slug recursif : cat1/cat2/[...]
            if (slugs.length - 1 >= l) {
                slugs.splice(l);
            }

            if ((section.action === 'catalog' && !section.slug) || (section.action === 'page' && (section.pageSlug === undefined || section.pageSlug === ''))) return '';
            if (section.action === 'catalog' && (!section.slug[lang] || section.slug[lang] === '')) return <li><a href="/">Non translated section</a></li>;

            let classLi = classnavulli;
            if (currentUrl.indexOf(`${section.pageSlug}`) > -1) {
                classLi = classLi ? ` ${classnavulliactive}` : classnavulliactive;
            }
            let classLiA = classnavullia;
            if (currentUrl.indexOf(`${section.pageSlug}`) > -1) {
                classLiA = classLiA ? ` ${classnavulliaactive}` : classnavulliaactive;
            }

            if (section.action === 'page') {
                slugs.push(section.pageSlug);
                let anchor = false;
                if (section.pageSlug.indexOf('#') > -1) {
                    anchor = true;
                }
                return (
                    <li key={`${section._id}_${this.props['ns-code']}`} className={classLi}>
                        {
                            Link !== undefined
                                ? (
                                    anchor ? (
                                        <Link route={`${urlLang}/${section.pageSlug}`}>
                                            <a className={classLiA}><span>{section.name} {arrowChildren}</span></a>
                                        </Link>
                                    ) : (
                                        <Link route="staticI18n" params={{ _slug: section.pageSlug || 'home',  lang: routerLang }}>
                                            <a className={classLiA}><span>{section.name} {arrowChildren}</span></a>
                                        </Link>
                                    )
                                )
                                : <a className={classLiA} href={`${urlLang}/${section.pageSlug}`}><span>{section.name} {arrowChildren}</span></a>
                        }
                        { section.children !== undefined && section.children.length ? <ul>{this.recursive(section, l + 1)}</ul> : '' }
                    </li>
                );
            }
            slugs.push(section.slug[lang]);
            if (client) {
            return (
                        <li key={`${section._id}_${this.props['ns-code']}`} className={classLi} >
                            {
                                Link !== undefined
                                    ? (
                                        <Link route="categoryI18n" params={{
                                            _slug: [...slugs] || '',
                                            page: undefined,
                                            lang: routerLang
                                        }}>
                                            <a className={classLiA} style={{color:'#9f1f0a'}}>
                                                {section.name} {arrowChildren}
                                            </a>
                                        </Link>
                                    )
                                    : (
                                        <a href={`${urlLang}/c/${slugs.join('/')}`} className={classLiA} style={{color:'#9f1f0a'}}>
                                        <span>
                                            {section.name} {arrowChildren}
                                        </span>
                                        </a>
                                    )
                            }

                            {section.children !== undefined && section.children.length ? <ul>{this.recursive(section, l + 1, slugs)}</ul> : ''}
                        </li>
                );
            };
        });
    }

    render() {
        const {
            gNext, classnav, classnavul, css
        } = this.props;
        const Head = (gNext && gNext.Head) || undefined;
        const { props } = this.context;
        if (!props) { return null; }
        const nsMenu = props[`nsMenu_${this.props['ns-code']}`];
        return (
            <>
                {
                    css !== '0' ? (
                        Head !== undefined
                            ? <Head><link href="/static/css/menu.css" rel="stylesheet" /></Head>
                            : <link href="/static/css/menu.css" rel="stylesheet" />
                    ) : null
                }
                <nav className={classnav}>
                    <ul className={classnavul}>
                        { nsMenu && this.recursive(nsMenu, 0) }
                    </ul>
                </nav>
            </>
        );
    }

    componentWillUnmount() {
        window.removeEventListener('routeChange', this.routeChangeListener);
    }
}

export default Menu;
